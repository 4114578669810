class Action {
    /**
   *
   */ static instanse(name) {
        return new this(name);
    }
    set callback(value) {
        this._callback = value;
    }
    get callback() {
        return this._callback;
    }
    set set(value) {
        this._callback = value;
    }
    get get() {
        return this._callback;
    }
    constructor(name){
        this.name = null;
        this._callback = ()=>{};
        this.name = name;
    }
}
export default Action;
