import compose from "recompose/compose";
import withProps from "recompose/withProps";
import withLoading from "@xem/utils/withLoading";
import Entity from "@xem/gen-common/classes/Gen";
import withGen, { GEN_QUERY } from "@xem/gen-common/classes/withGen";
import withGenDeleting from "@xem/gen-common/classes/withGenDeleting";
import setCache from "@xem/entity/classes/setCache";
/**
 *
 * @param input
 * @param withPropsInput
 * @param withPropsBefore
 */ export default ((input, withPropsInput, withPropsBefore)=>{
    return (Route)=>{
        function getEntity(param) {
            let { query  } = param;
            /*** entity ID*/ let entity = Entity.instanse();
            entity.id = parseInt(query === null || query === void 0 ? void 0 : query.id);
            return entity;
        }
        /**
		 * WithRouteLink
		 */ const WithRouteLink = compose(withProps(input), withGenDeleting, withProps((param)=>{
            let { query  } = param;
            return {
                entity: getEntity({
                    query
                })
            };
        }), ...withPropsBefore || [], withGen((param)=>{
            let { entity  } = param;
            return {
                gen: entity,
                entity_id: entity === null || entity === void 0 ? void 0 : entity.id
            };
        }), ...withPropsInput || [], withLoading)(Route);
        const bindCache = async (apolloClient, query, obj)=>{
            let entity = getEntity(obj);
            let variables = {
                id: entity.id
            };
            /** set cache*/ await setCache({
                query,
                variables,
                apolloClient
            });
        };
        // @ts-ignore
        WithRouteLink.getInitialProps = async (param)=>{
            let { apolloClient , ...ctx } = param;
            ctx = ctx || {};
            await bindCache(apolloClient, GEN_QUERY, {
                ...ctx
            });
            return {
                ...ctx
            };
        };
        return WithRouteLink;
    };
});
