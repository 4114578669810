export default async function setCache(param) {
    let { apolloClient , query , variables  } = param;
    /** Preload content **/ let result = await apolloClient.query({
        query,
        variables,
        fetchPolicy: "cache-first"
    });
    /** set cache content **/ await apolloClient.writeQuery({
        query,
        variables,
        data: result === null || result === void 0 ? void 0 : result.data
    });
}
