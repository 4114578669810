import compose from "recompose/compose";
import withProps from "recompose/withProps";
import withLoading from "@xem/utils/withLoading";
import Entity from "@xem/page-common/classes/Page";
import withPage, { PAGE_QUERY } from "@xem/page-common/classes/withPage";
import setCache from "@xem/entity/classes/setCache";
/**
 *
 * @param input
 * @param withPropsInput
 * @param withPropsBefore
 */ export const ModulesCompose = (input, withPropsInput, withPropsBefore)=>{
    return (Route)=>{
        /**
		 * witRoute
		 */ const witRoute = compose(withProps(input), withProps({
            entity: Entity.instanse()
        }), withProps((param)=>{
            let { entity , action  } = param;
            const refetch = ()=>{
                entity && entity.refetch && entity.refetch();
            };
            action.callback = refetch;
            return {
                refetch
            };
        }), ...withPropsBefore || [], withPage((param)=>{
            let { entity  } = param;
            return {
                page: entity
            };
        }), ...withPropsInput || [], withLoading)(Route);
        const bindCache = async (apolloClient, query, variables)=>{
            /** set link cache*/ await setCache({
                query,
                variables,
                apolloClient
            });
        };
        // @ts-ignore
        witRoute.getInitialProps = async (param)=>{
            let { path , apolloClient  } = param;
            let ctx = {};
            await bindCache(apolloClient, PAGE_QUERY, {
                path
            });
            return {
                ...ctx
            };
        };
        return witRoute;
    };
};
export default ModulesCompose;
