import RenderField from "@xem/look/Renders/Field";
export const btnProps = {
    outline: true,
    className: "rounded-0",
    color: "secondary"
};
export function BaseConfig(props) {
    let { setFieldValue , tabIndex , name , value , ...rest } = props;
    return {
        ...{
            onRemove: setFieldValue
        },
        ...{
            component: RenderField,
            inputProps: {
                tabIndex: tabIndex++
            }
        },
        ...{
            name,
            value
        },
        ...rest
    };
}
export const getFilterByKey = (key)=>{
    let orderBy = "CREATED";
    let direction = "DESC";
    let status = "active";
    /**
   * Order by
   */ if ([
        "viewsLeast",
        "viewsMost"
    ].includes(key)) {
        orderBy = "VIEWS";
    }
    if ([
        "viewsQrcode"
    ].includes(key)) {
        orderBy = "VIEWS_QRCODE";
    }
    if ([
        "viewsLatest"
    ].includes(key)) {
        orderBy = "VIEWS_LATEST";
    }
    if ([
        "az",
        "za"
    ].includes(key)) {
        orderBy = "TITLE";
    }
    /**
   * End order by
   */ /**
   * Direction
   */ if ([
        "az",
        "longest",
        "least",
        "viewsLeast"
    ].includes(key)) {
        direction = "ASC";
    }
    if (key === "unpublished") {
        status = "inactive";
    }
    return {
        status,
        direction,
        orderBy
    };
};
