const onSubmit = function onSubmit(onSave, entity, refetch) {
    return function onSubmit(param, param1) {
        let { qr_default , ...qr } = param, { onClose  } = param1;
        onSave({
            id: entity === null || entity === void 0 ? void 0 : entity.id,
            qr,
            qr_default
        }).then(()=>{
            refetch && refetch();
            onClose && onClose();
        }).catch(errorCatch);
    };
};
export default onSubmit;
