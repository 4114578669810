import loadable from "next/dynamic";
import { map } from "../helpers/config";
import withProps from "recompose/withProps";
import withRenderTools from "@xem/look/Renders/Tools/withTools";
import withRenderProcess from "@xem/entity/classes/withRenderProcess";
import withFormFilter from "../helpers/links/Filter";
import withOnFilter from "../helpers/links/withOnFilter";
import LinksCompose from "../helpers/links/LinksCompose";
const ListComponent = loadable(()=>import("../components/list/LinksView").then((c)=>c.default), {
    loadableGenerated: {
        webpack: ()=>[
                require.resolveWeak("../components/list/LinksView")
            ]
    }
});
export default LinksCompose({}, [
    withProps((param)=>{
        let { query  } = param;
        return {
            pageProps: {
                query,
                path: map({
                    path: "list"
                }),
                other: map({
                    path: "listpage",
                    page: ":page"
                })
            }
        };
    }),
    withProps((param)=>{
        let { entities , setFieldValue  } = param;
        return {
            refetch: ()=>{
                try {
                    entities.refetch();
                } catch (e) {}
                try {
                    setFieldValue("page", 1);
                } catch (e1) {}
            }
        };
    }),
    withFormFilter
], [
    withRenderTools,
    withOnFilter,
    withRenderProcess({
        entityType: "link"
    })
])(ListComponent);
