import withProps from "recompose/withProps";
export default withProps({
    selecterSx: {
        "&": {
            zIndex: 1000,
            position: "absolute",
            bottom: "0px",
            left: 0,
            right: 0,
            paddingTop: "0",
            backgroundColor: "#f2f2f2"
        }
    },
    sx: {
        "&": {
            zIndex: 1000,
            position: "absolute",
            top: "0px",
            left: 0,
            right: 0,
            paddingTop: "0",
            backgroundColor: "#f2f2f2"
        },
        "& .control-form": {
            padding: "7px 10px",
            backgroundColor: "#fff",
            "& .icon": {
                "&.delete": {
                    color: "#000",
                    "&.active": {
                        color: "#C21C21"
                    }
                },
                "&.hide": {
                    color: "#000",
                    "&.active": {
                        color: "#C21C21"
                    }
                }
            },
            "& .MuiInputBase-adornedStart": {
                paddingLeft: 0
            },
            "& .MuiFormControl-root": {
                "&.keyword": {
                    "& .startAdornment": {
                        svg: {
                            "#Rectangle_1739": {
                                fill: "#f2f2f2"
                            }
                        }
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                        border: "none"
                    },
                    "& .MuiInputBase-root": {
                        backgroundColor: "#f2f2f2",
                        borderRadius: "36px",
                        "& .MuiInputBase-input": {
                            padding: "6.5px 10px 6.5px 0"
                        }
                    },
                    marginTop: 0,
                    marginBottom: 0,
                    paddingRight: "5px"
                }
            },
            "& .btn": {
                "&.icon": {
                    padding: "0 0 0 10px"
                }
            }
        }
    }
});
