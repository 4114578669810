import { graphql } from "react-apollo";
import mapInput from "@gqlapp/base/utils/mapInput";
import removeTypename from "@gqlapp/base/utils/removeTypename";
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import GEN_QUERY from "../graphql/GenQuery.graphql";
const withGen = (input)=>compose(withProps((props)=>mapInput(input, props)), graphql(GEN_QUERY, {
        skip: (param)=>{
            let { entity_id  } = param;
            return !entity_id;
        },
        options: (param)=>{
            let { entity_id , fetchPolicy  } = param;
            return {
                fetchPolicy,
                variables: {
                    id: parseInt(entity_id)
                }
            };
        },
        props (param) {
            let { data: { loading , res , refetch , error  } , ownProps: { gen  }  } = param;
            if (!loading && res) {
                gen.save(removeTypename(res));
            }
            return {
                loading,
                genRefetch: refetch,
                errors: error ? error.graphQLErrors : null
            };
        }
    }));
export { GEN_QUERY };
export default withGen;
