import loadingProcess from "@gqlapp/base/utils/loading/loading";
/**
 * QueryLoading
 * @param loadbar
 * @param subscribeToMore
 * @param QUERY
 * @constructor
 */ function QueryLoading(loadbar, subscribeToMore, QUERY) {
    return async (variables)=>{
        return await loadingProcess(loadbar, async ()=>new Promise(async (resolve)=>{
                try {
                    subscribeToMore({
                        document: QUERY,
                        variables: variables,
                        updateQuery: async (_, param)=>{
                            let { subscriptionData: { data: { res  }  }  } = param;
                            resolve(res);
                        }
                    });
                } catch (e) {
                    resolve({});
                }
            }));
    };
}
export default QueryLoading;
