import loadable from "next/dynamic";
import DomainsCompose from "../helpers/DomainsCompose";
import withProps from "recompose/withProps";
import { map } from "../helpers/config";
import withFormFilter from "../helpers/Filter";
import withListProcess from "../helpers/MediaTools";
import withRenderProcess from "@xem/entity/classes/withRenderProcess";
const ListContainer = loadable(()=>import("./List").then((c)=>c.default), {
    loadableGenerated: {
        webpack: ()=>[
                require.resolveWeak("./List")
            ]
    }
});
export default DomainsCompose({}, [
    withProps((param)=>{
        let { query  } = param;
        return {
            pageProps: {
                path: map({
                    path: "list"
                }),
                other: map({
                    path: "listpage",
                    page: ":page"
                }),
                query
            }
        };
    }),
    withProps((param)=>{
        let { entities , setFieldValue  } = param;
        return {
            refetch: ()=>{
                try {
                    entities.refetch();
                } catch (e) {}
                try {
                    setFieldValue("page", 1);
                } catch (e1) {}
            }
        };
    }),
    withFormFilter
], [
    withListProcess,
    withRenderProcess({
        entityType: "domain"
    })
])(ListContainer);
