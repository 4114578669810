import compose from "recompose/compose";
import { withFormik } from "formik";
import Style from "./Style";
import FilterConfirm from "./Filter.Confirm";
let timeout = null;
export default compose(Style, FilterConfirm, withFormik({
    enableReinitialize: true,
    displayName: "EntitiesDeleteForm",
    validate: (values, param)=>{
        let { setValues  } = param;
        clearTimeout(timeout);
        timeout = setTimeout(()=>{
            if (!values.onSelect) {
                Object.assign(values, {
                    ids: []
                });
            }
            setValues(values);
        }, 100);
    },
    mapPropsToValues: (param)=>{
        let { values  } = param;
        return values;
    },
    handleSubmit: async function(values, param) {
        let { props: { entities , filterConfirm , onUpdates , onDeletes  }  } = param;
        let { ids , onSelect  } = values;
        let type = null;
        switch(onSelect){
            case 1:
                type = "delete";
                break;
            case 2:
                type = "hide";
                break;
        }
        /**
       * handleSubmit
       * @param onClose
       * @param filterConfirm
       */ function handleSubmit(param, param1) {
            let {} = param, { props: { onClose  }  } = param1;
            let { ids , onSelect  } = values || {};
            let value;
            if (onSelect === 1) {
                value = onDeletes({
                    ids
                });
            } else if (onSelect === 2) {
                value = onUpdates({
                    ids,
                    input: {
                        status: "inactive"
                    }
                });
            }
            if (value) {
                value.then(func(entities === null || entities === void 0 ? void 0 : entities.refetch)).then(func(onClose));
            }
        }
        filterConfirm(ids, type, handleSubmit);
    }
}));
