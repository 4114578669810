"use strict";
module.exports = {
    "en": {
        "title": "",
        "breadcrumb": {
            "list": "Member List",
            "profile": "Account Information"
        },
        "list": {
            "btn": {
                "avatar": "Change Avatar",
                "profile": "Change Member Information",
                "role": "Edit Role",
                "settings": "Edit Settings",
                "block": "Block Account",
                "unblock": "Unblock Account",
                "delete": "Delete Account"
            }
        },
        "home": "Home",
        "login": "Login",
        "register": "Register",
        "forgotPassword": "Forgot Password",
        "free-register": "Free Register",
        "logout": "Logout",
        "next": "Next",
        "verification": "Verification",
        "account": {
            "existed": "Account already exists",
            "noExisted": "No account exists?",
            "social": {
                "phone": {
                    "verify": "Confirm phone number: {{value}}"
                }
            },
            "phone": {
                "verify": "Verify phone number",
                "code": "Enter Verification Code",
                "note": "We use your phone number to keep your account secure. Your phone number will not be shown to others.",
                "notity": "{{sitename}} will send a text message containing a 6-digit verification code. Enter it in your phone number."
            },
            "login": {
                "label": "Login",
                "or": "Or login with"
            },
            "register": {
                "or": "Or register with",
                "keyword": "Use 8 or more characters and a combination of letters, numbers and symbols"
            },
            "forgot.description": "Please enter your email address and we will send you a link to create a new password."
        },
        "status": {
            "active": "Active",
            "inactive": "Inactive"
        },
        "resetPass": {
            "title": "Create New Password",
            "meta": "Forgot Password Page",
            "errorMsg": "Password reset failed!",
            "form": {
                "title": "Create New Password",
                "msg": "Use {{value}} characters or more and combine letters, numbers, and symbols",
                "fldEmail": "Email",
                "btnSubmit": "Complete",
                "field": {
                    "pass": "New Password",
                    "passConf": "Confirm Password"
                }
            }
        },
        "pass": {
            "title": "Retrieve Password",
            "meta": "Forgot Password Page",
            "errorMsg": "Password reset failed!",
            "form": {
                "submitMsg": "Password reset instructions have been sent to your email.",
                "title": "Retrieve Password",
                "fldEmail": "Email",
                "field.name": "Email Address",
                "btnSubmit": "Retrieve Password"
            }
        },
        "label": {
            "add": "Add Member",
            "list": "Member List",
            "login": "Login",
            "register": "Register",
            "profile": "Account",
            "free-register": "Free Register",
            "forgotPass": "Find Password",
            "reset": "Reset Password",
            "logout": "Logout",
            "inbox": "Inbox",
            "contact": "Contact",
            "users.filter": "Sort Display"
        },
        "role": {
            "label": "Role",
            "all": "All",
            "anonymous": "Guest",
            "authenticated": "Member",
            "root": "Super Admin",
            "administrator": "Super Admin",
            "admin": "Admin",
            "moderator": "Moderator",
            "host": "Host",
            "business": "Business",
            "professional": "Professional",
            "prob": "Probation",
            "sale": "Sale"
        },
        "views": {
            "az": "A to Z Order",
            "za": "Z to A Order"
        },
        "users": {
            "title": "Member List",
            "column": {
                "name": "Account Name",
                "roles": "Roles",
                "email": "Email",
                "role": "Role",
                "active": "Active",
                "actions": "Actions"
            },
            "btn.add": "Add Member",
            "list.title": "Member List"
        },
        "update": {
            "saved": "Update Successful",
            "errorMsg": "Unknown Error"
        },
        "popup": {
            "area": "Select Area",
            "name": "Change Name",
            "info": "Account",
            "password": "Change Password",
            "mail": "Change Email",
            "address": "Edit Contact Address",
            "birthday": "Change Birthday",
            "phone": "Change Phone Number",
            "roles": "Roles",
            "confirm": "Enter Verification Code",
            "avatar": "Change Avatar",
            "country": "Country"
        },
        "userEdit": {
            "form": {
                "sex": {
                    "other": "Other",
                    "female": "Female",
                    "male": "Male"
                },
                "name": {
                    "firstname": "First Name",
                    "lastname": "Last Name",
                    "middle": "Middle Name"
                },
                "password": {
                    "old": "Old Password",
                    "new": "New Password",
                    "confirm": "Confirm Password"
                },
                "address": {
                    "address": "Address",
                    "address2": "Address 2",
                    "city": "City",
                    "state": "State/Province",
                    "zipcode": "Zip Code",
                    "country": "Country"
                },
                "birthday": {
                    "day": "Day",
                    "month": "Month",
                    "year": "Year"
                },
                "field": {
                    "codeConfirm": "{{sitename}} will send a text message containing a 6-digit verification code to your phone number.",
                    "keyword": "Search",
                    "display": "Full Name",
                    "address": "Address",
                    "female": "Female",
                    "male": "Male",
                    "other": "Other",
                    "displayName": "Full Name",
                    "firstName": "First Name",
                    "lastName": "Last Name",
                    "sex": "Gender",
                    "birthday": "Birthday",
                    "day": "Day",
                    "month": "Month",
                    "year": "Year",
                    "phone": "Phone Number",
                    "currentPassword": "Current Password",
                    "pass": "Password",
                    "password": "Current Password",
                    "new_password": "New Password",
                    "Confirm_password": "Confirm Password",
                    "username": "Username",
                    "mail": "Email",
                    "active": "Active",
                    "subscribe": "Keep me updated on promotions and news related to {{value}}"
                }
            }
        },
        "profile": {
            "label": "Account",
            "title": "Account Information",
            "breadcrumb": "Account Information",
            "headerText": "Account Information",
            "meta": "Account Information Page",
            "loadMsg": "Loading...",
            "errorMsg": "No current user logged in",
            "editProfileText": "Edit Profile",
            "company": {
                "pattern": "Pattern",
                "serial": "Serial",
                "name": "Company Name",
                "code": "Tax Code",
                "address": "Address",
                "phone": "Phone",
                "fax": "Fax Number",
                "mail": "Email",
                "represent": "Representative",
                "bank_no": "Bank Account Number",
                "bank_name": "Bank Name",
                "account_holder": "Account Holder"
            },
            "form": {
                "field": {
                    "female": "Female",
                    "male": "Male",
                    "other": "Other",
                    "displayName": "Full Name",
                    "firstName": "First Name",
                    "lastName": "Last Name",
                    "sex": "Gender",
                    "birthday": "Birthday",
                    "day": "Day",
                    "month": "Month",
                    "year": "Year",
                    "phone": "Phone",
                    "password": "Current Password",
                    "new_password": "New Password",
                    "Confirm_password": "Confirm Password",
                    "username": "Username",
                    "mail": "Email",
                    "subscribe": "Keep me updated on promotions and news related to {{value}}"
                }
            },
            "btn": {
                "save": "Save Changes",
                "fbBtn": "Connect with Facebook Account",
                "googleBtn": "Connect with Google Account",
                "githubBtn": "Connect with GitHub Account",
                "linkedinBtn": "Connect with LinkedIn Account"
            }
        },
        "notify": {
            "delete": 'Are you sure you want to delete member<br /><span style="color: #006621">{{value}}</span>?',
            "block": 'Are you sure you want to block member<br /><span style="color: #006621">{{value}}</span>?',
            "unblock": 'Are you sure you want to unblock member<br /><span style="color: #006621">{{value}}</span>?',
            "odd.title": "List of {{value}} user",
            "even.title": "List of {{value}} users",
            "unpublished": {
                "odd.title": "List of {{value}} hidden user",
                "even.title": "List of {{value}} hidden users"
            }
        }
    },
    "global": {
        "Start a {{value}}-Day Free Trial": "D\xf9ng thử {{value}} ng\xe0y miễn ph\xed"
    },
    "vi": {
        "title": "",
        "breadcrumb": {
            "list": "Danh s\xe1ch th\xe0nh vi\xean",
            "profile": "Th\xf4ng tin t\xe0i khoản"
        },
        "list": {
            "btn": {
                "avatar": "Thay đổi avatar",
                "profile": "Thay đổi Th\xf4ng tin th\xe0nh vi\xean",
                "role": "Chỉnh sửa ph\xe2n quyền",
                "settings": "Chỉnh sửa ph\xe2n quyền",
                "block": "Kho\xe1 t\xe0i khoản",
                "unblock": "Mở kho\xe1 t\xe0i khoản",
                "delete": "Xo\xe1 t\xe0i khoản"
            }
        },
        "home": "Trang chủ",
        "login": "Đăng nhập",
        "register": "Đăng k\xfd",
        "forgotPassword": "Lấy Lại Mật Khẩu",
        "free-register": "Đăng k\xfd miễn ph\xed",
        "logout": "Đăng xuất",
        "next": "Tiếp Theo",
        "verification": "X\xe1c Minh",
        "status": {
            "active": "Đang hoạt động",
            "inactive": "Kh\xf4ng hoạt động"
        },
        "resetPass": {
            "title": "Tạo Lại Mật Khẩu Mới",
            "meta": "Trang qu\xean mật khẩu",
            "errorMsg": "Đặt lại mật khẩu kh\xf4ng th\xe0nh c\xf4ng!",
            "form": {
                "title": "Tạo Lại Mật Khẩu Mới",
                "msg": "Sử dụng {{value}} k\xfd tự trở l\xean v\xe0 kết hợp chữ c\xe1i, chữ số v\xe0 biểu tượng",
                "fldEmail": "Email",
                "btnSubmit": "Ho\xe0n Th\xe0nh",
                "field": {
                    "pass": "Mật khẩu mới",
                    "passConf": "Nhập lại mật khẩu"
                }
            }
        },
        "pass": {
            "title": "Lấy Lại Mật Khẩu",
            "meta": "Trang qu\xean mật khẩu",
            "errorMsg": "Đặt lại mật khẩu kh\xf4ng th\xe0nh c\xf4ng!",
            "form": {
                "submitMsg": "Hướng dẫn đặt lại mật khẩu của bạn đ\xe3 được gửi qua email cho bạn.",
                "title": "Lấy Lại Mật Khẩu",
                "fldEmail": "Email",
                "field.name": "Địa chỉ EMail",
                "btnSubmit": "Lấy Lại Mật Khẩu"
            }
        },
        "label": {
            "add": "Th\xeam th\xe0nh vi\xean",
            "list": "Danh s\xe1ch th\xe0nh vi\xean",
            "login": "Đăng nhập",
            "register": "Đăng k\xfd",
            "profile": "T\xe0i khoản",
            "free-register": "Đăng k\xfd miễn ph\xed",
            "forgotPass": "T\xecm mật khẩu",
            "reset": "Đặt lại mật khẩu",
            "logout": "Đăng xuất",
            "inbox": "Hộp thư",
            "contact": "Li\xean hệ",
            "users.filter": "Sắp xếp hiển thị"
        },
        "role": {
            "label": "Quyền",
            "all": "Tất cả",
            "anonymous": "Kh\xe1ch",
            "authenticated": "Th\xe0nh vi\xean",
            "root": "Quản trị cấp cao",
            "administrator": "Quản trị cấp cao",
            "admin": "Quản trị",
            "moderator": "Quản trị",
            "host": "Chủ nh\xe0",
            "business": "Doanh nghiệp",
            "professional": "Chuy\xean nghiệp",
            "prob": "Tập sự",
            "sale": "Khuyến m\xe3i"
        },
        "views": {
            "az": "Thứ tự từ A tới Z",
            "za": "Thứ tự từ Z tới A"
        },
        "users": {
            "title": "Danh s\xe1ch th\xe0nh vi\xean",
            "column": {
                "name": "T\xean t\xe0i khoản",
                "roles": "Ph\xe2n quyền",
                "email": "Email",
                "role": "Vai tr\xf2",
                "active": "Đang hoạt động",
                "actions": "H\xe0nh động"
            },
            "btn.add": "Th\xeam th\xe0nh vi\xean",
            "list.title": "Danh s\xe1ch th\xe0nh vi\xean"
        },
        "update": {
            "saved": "Cập nhật th\xe0nh c\xf4ng",
            "errorMsg": "Lỗi kh\xf4ng x\xe1c định"
        },
        "popup": {
            "area": "Chọn v\xf9ng",
            "name": "Thay đổi họ v\xe0 t\xean",
            "info": "T\xe0i khoản",
            "password": "Thay đổi mật khẩu",
            "mail": "Thay đổi email",
            "address": "Chỉnh sửa địa chỉ li\xean hệ",
            "birthday": "Thay đổi ng\xe0y sinh",
            "phone": "Thay đổi số điện thoại",
            "roles": "Ph\xe2n quyền",
            "confirm": "Nhập m\xe3 x\xe1c minh",
            "avatar": "Thay đổi ảnh đại diện",
            "country": "Quốc gia"
        },
        "userEdit": {
            "form": {
                "sex": {
                    "other": "Kh\xe1c",
                    "female": "Nữ",
                    "male": "Nam"
                },
                "name": {
                    "firstname": "T\xean",
                    "lastname": "Họ",
                    "middle": "T\xean đệm"
                },
                "password": {
                    "old": "Mật khẩu cũ",
                    "new": "Mật khẩu mới",
                    "confirm": "Nhập lại mật khẩu"
                },
                "address": {
                    "address": "Địa chỉ",
                    "address2": "Địa chỉ 2",
                    "city": "Th\xe0nh phố",
                    "state": "Tiểu bang - Tỉnh",
                    "zipcode": "M\xe3 v\xf9ng",
                    "country": "Quốc gia"
                },
                "birthday": {
                    "day": "Ng\xe0y",
                    "month": "Th\xe1ng",
                    "year": "Năm"
                },
                "field": {
                    "codeConfirm": "{{sitename}} sẽ gửi một tin nhắn văn bản bao gồm m\xe3 x\xe1c minh <br /> gồm 6 chữ số. V\xe0o số điện thoại của bạn.",
                    "keyword": "T\xecm kiếm",
                    "display": "Họ v\xe0 t\xean",
                    "address": "Địa chỉ",
                    "female": "Nữ",
                    "male": "Nam",
                    "other": "Kh\xe1c",
                    "displayName": "Họ v\xe0 t\xean",
                    "firstName": "T\xean",
                    "lastName": "Họ v\xe0 t\xean đệm",
                    "sex": "Giới t\xednh",
                    "birthday": "Ng\xe0y sinh",
                    "day": "Ng\xe0y",
                    "month": "Th\xe1ng",
                    "year": "Năm",
                    "phone": "Số điện thoại",
                    "currentPassword": "Mật khẩu hiện tại",
                    "pass": "Mật khẩu",
                    "password": "Mật khẩu hiện tại",
                    "new_password": "Mật khẩu mới",
                    "Confirm_password": "Nhập lại mật khẩu",
                    "username": "T\xean đăng nhập",
                    "mail": "Thư điện tử",
                    "active": "Hoạt động",
                    "subscribe": "Cập nhật cho t\xf4i về khuyến m\xe3i cũng như c\xe1c tin tức li\xean quan đến {{value}}"
                }
            }
        },
        "profile": {
            "label": "T\xe0i khoản",
            "title": "Th\xf4ng tin t\xe0i khoản",
            "breadcrumb": "Th\xf4ng tin t\xe0i khoản",
            "headerText": "Th\xf4ng tin t\xe0i khoản",
            "meta": "Trang th\xf4ng tin t\xe0i khoản",
            "loadMsg": "Loading...",
            "errorMsg": "Kh\xf4ng c\xf3 người d\xf9ng hiện tại n\xe0o đăng nhập",
            "editProfileText": "Chỉnh sửa hồ sơ",
            "company": {
                "pattern": "Mẫu số",
                "serial": "K\xfd hiệu",
                "name": "T\xean c\xf4ng ty",
                "code": "M\xe3 số thuế",
                "address": "Địa chỉ",
                "phone": "Điện thoại",
                "fax": "Số Fax",
                "mail": "Email",
                "represent": "Người đại diện",
                "bank_no": "Số t\xe0i khoản",
                "bank_name": "T\xean ng\xe2n h\xe0ng",
                "account_holder": "Chủ t\xe0i khoản"
            },
            "form": {
                "field": {
                    "female": "Nữ",
                    "male": "Nam",
                    "other": "Kh\xe1c",
                    "displayName": "Họ v\xe0 t\xean",
                    "firstName": "T\xean",
                    "lastName": "Họ v\xe0 t\xean đệm",
                    "sex": "Giới t\xednh",
                    "birthday": "Ng\xe0y sinh",
                    "day": "Ng\xe0y",
                    "month": "Th\xe1ng",
                    "year": "Năm",
                    "phone": "Phone",
                    "password": "Mật khẩu hiện tại",
                    "new_password": "Mật khẩu mới",
                    "Confirm_password": "Nhập lại mật khẩu",
                    "username": "T\xean đăng nhập",
                    "mail": "Thư điện tử",
                    "subscribe": "Cập nhật cho t\xf4i về khuyến m\xe3i cũng như c\xe1c tin tức li\xean quan đến {{value}}"
                }
            },
            "btn": {
                "save": "Lưu thay đổi",
                "fbBtn": "Kết nối với t\xe0i khoản Facebook",
                "googleBtn": "Kết nối với t\xe0i khoản Google",
                "githubBtn": "Kết nối với t\xe0i khoản github",
                "linkedinBtn": "Kết nối với t\xe0i khoản linkedin"
            }
        },
        "notify": {
            "delete": 'Bạn c\xf3 chắc m\xecnh sẽ xo\xe1 th\xe0nh vi\xean<br /><span style="color: #006621">{{value}}</span> chứ ?',
            "block": 'Bạn c\xf3 chắc m\xecnh sẽ kho\xe1 th\xe0nh vi\xean<br /><span style="color: #006621">{{value}}</span> chứ ?',
            "unblock": 'Bạn c\xf3 chắc m\xecnh sẽ mở kho\xe1 th\xe0nh vi\xean<br /><span style="color: #006621">{{value}}</span> chứ ?',
            "odd.title": "Danh s\xe1ch {{value}} user",
            "even.title": "Danh s\xe1ch {{value}} users",
            "unpublished": {
                "odd.title": "Danh s\xe1ch {{value}} user ẩn",
                "even.title": "Danh s\xe1ch {{value}} users ẩn"
            }
        }
    }
};
