import compose from "recompose/compose";
import withProps from "recompose/withProps";
import withLoading from "@xem/utils/withLoading";
import Entities from "@xem/custom-page-common/classes/CustomPages";
import withCustomPages, { CUSTOM_PAGES_QUERY } from "@xem/custom-page-common/classes/withCustomPages";
import withCustomPagesDeleting from "@xem/custom-page-common/classes/withCustomPagesDeleting";
import onDeletes from "../utils/onDeletes";
import withTools from "@xem/look/Renders/Tools/withTools";
import setCache from "@xem/entity/classes/setCache";
/**
 *
 * @param input
 * @param withPropsInput
 * @param withPropsBefore
 */ export const ModulesCompose = (input, withPropsInput, withPropsBefore)=>{
    return (Route)=>{
        const getEntities = function getPath(param) {
            let { query , status ="active"  } = param;
            let id = parseInt(query === null || query === void 0 ? void 0 : query.id);
            let entities = Entities.instanse({
                pageSize: 16,
                orderBy: "CHANGED",
                protected: true
            });
            /**
			 * Custom page filter
			 */ let filter = {};
            !!id && Object.assign(filter, {
                tids: [
                    id
                ]
            });
            !!status && Object.assign(filter, {
                status
            });
            return entities;
        };
        /**
		 * WithRouteBase
		 */ const WithRouteBase = compose(withTools, withProps(input), withCustomPagesDeleting, withProps((param)=>{
            let { customPagesDelete , query , cookie , ...props } = param;
            var ref;
            return {
                onDeletes: onDeletes(customPagesDelete, props),
                entities: getEntities({
                    query,
                    cookie: cookie === null || cookie === void 0 ? void 0 : (ref = cookie.filter) === null || ref === void 0 ? void 0 : ref.key
                })
            };
        }), withProps((param)=>{
            let { entities , action  } = param;
            function refetch() {
                func(entities === null || entities === void 0 ? void 0 : entities.refetch)();
            }
            action.callback = refetch;
            return {
                refetch
            };
        }), ...withPropsBefore || [], withCustomPages((param)=>{
            let { entities  } = param;
            return {
                customPages: entities
            };
        }), ...withPropsInput || [], withLoading)(Route);
        const bindCache = async (apolloClient, query, obj)=>{
            let entities = getEntities(obj);
            let variables = entities.variables;
            /** set cache*/ await setCache({
                query,
                variables,
                apolloClient
            });
        };
        // @ts-ignore
        WithRouteBase.getInitialProps = async (param)=>{
            let { apolloClient  } = param;
            let ctx = {};
            await bindCache(apolloClient, CUSTOM_PAGES_QUERY, {});
            return {
                ...ctx
            };
        };
        return WithRouteBase;
    };
};
export default ModulesCompose;
