import compose from "recompose/compose";
import withProps from "recompose/withProps";
import { withContentCallback } from "@xem/entity/classes/preloadContents";
import Domains from "@xem/domain-common/classes/Domains";
import DOMAINS_QUERY from "@xem/domain-common/graphql/domainsQuery.graphql";
import QueryLoading from "@xem/entity/classes/QueryLoading";
function domainSearch(props) {
    let { setFieldValue , loadbar , userSubscribeToMore  } = props;
    let domains = Domains.instanse({
        filter: {
            type: [
                "ns"
            ],
            get_default: true,
            public: true
        }
    });
    return async function() {
        setFieldValue("pageLoading", true);
        domains.update(await QueryLoading(loadbar, userSubscribeToMore, DOMAINS_QUERY)(domains.variables));
        setFieldValue("pageLoading", false);
        return domains;
    };
}
export function domainCallback(props) {
    let { onActions  } = props || {};
    let search = domainSearch(props);
    return async function(rest) {
        let options = [], domains = await search();
        domains.items.map((domain)=>{
            options.push({
                value: domain.id,
                label: domain.full
            });
        });
        onActions["domain.search"] && onActions["domain.search"]({
            ...rest,
            options
        });
    };
}
export default compose(withContentCallback((res)=>res, "loadDomain", DOMAINS_QUERY), withProps((param)=>{
    let { setFieldValue , loadDomain  } = param;
    function domainSearch(props) {
        let domains = Domains.instanse({
            filter: {
                type: [
                    "ns"
                ],
                get_default: true,
                public: true
            }
        });
        return async function() {
            setFieldValue("pageLoading", true);
            loadDomain && domains.update(await loadDomain(domains.variables));
            setFieldValue("pageLoading", false);
            return domains;
        };
    }
    return {
        domainSearch
    };
}), withProps((param)=>{
    let { domainSearch , onActions  } = param;
    function domainCallback(values) {
        let search = domainSearch(values);
        return async function(rest) {
            let options = [], domains = await search();
            domains.items.map((domain)=>{
                options.push({
                    value: domain.id,
                    label: domain.full
                });
            });
            onActions["domain.search"] && onActions["domain.search"]({
                ...rest,
                options
            });
        };
    }
    return {
        domainCallback
    };
}));
