import React from "react";
import settings from "@gqlapp/config";
// tslint:disable-next-line
const reactI18next = settings.i18n.enabled ? require("react-i18next") : null;
export const translate = (ns)=>{
    return (Component)=>{
        if (settings.i18n.enabled) {
            return reactI18next.withTranslation(ns)(Component);
        } else {
            // eslint-disable-next-line react/display-mail
            return (props)=>React.createElement(Component, {
                    ...props,
                    t: (key)=>key
                });
        }
    };
};
