export class Entities {

  private _path: string | undefined;


  protected entities: any[] = [];

  protected _filter: any;
  protected _page: number = 1;
  protected _pageSize: number = 20;
  protected _direction: string | undefined;
  protected _orderBy: string | undefined;

  // @ts-ignore
  private _entity_interface: any = Object;

  public count: number  = 0;
  private _refetch: any   = null;
  public type: string  = null;


  constructor(props?: any) {
    const { page, pageSize, path, filter, direction, orderBy, type } = props || {};
    this.path       = path || '__NULL';
    this.page       = page || 1;
    this.pageSize   = pageSize || 20;
    this.filter     = filter || null;
    this.direction  = direction || 'DESC';
    this.orderBy    = orderBy || 'CHANGED';
    this.type       = type || null;
  }

  /**
   *
   */
  public static instanse(props?: any) {
    return (new this(props)).save([]);
  }

  set interface(value: any) {
    this._entity_interface = value;
  }
  /**
   * reset
   */
  public reset() {
    this.entities = [];
    this.count = 0;
    // delete this.path;
  }

  set path(value: string) {
    this._path = value;
  }

  get path() {
    // @ts-ignore
    return this._path;
  }



  set refetch(value: string) {
    this._refetch = value;
  }

  get refetch() {
    // @ts-ignore
    return ()=>{
      this.page = 1;
      this._refetch();
    };
  }

  get pageCount(){
    return Math.ceil(this.count / this.pageSize) || 0;
  }
  /**
   * get page
   */
  get direction() {
    return this._direction || 'DESC';
  }

  set direction(value) {
    this._direction = value;
  }

  /**
   * get page
   */
  get orderBy() {
    return this._orderBy || 'CHANGED';
  }

  set orderBy(value) {
    this._orderBy = value;
  }

  /**
   * get page
   */
  get pageSize() {
    return this._pageSize || 12;
  }

  set pageSize(value) {
    this._pageSize = value;
  }

  /**
   * get page
   */
  get page() {
    return this._page || 1;
  }
  set page(value) {
    this._page = value;
  }

  /**
   * get page
   */
  get filter() {
    return this._filter || null;
  }

  set filter(value) {
    this._filter = { ...this.filter, ...value};
  }

  /**
   * get pager
   */
  get variables(): any {
    return {
      pageSize: this.pageSize, orderBy: this.orderBy, direction: this.direction,
      page: this.page, filter: this.filter, type: this.type
    };
  }

  get first() { return [...this.entities].shift(); }

  get last() { return [...this.entities].pop(); }

  /**
   * get items
   */
  get items() { return this.entities; }

  /**
   * get length
   */
  get length() {
    return this.entities.length;
  }

  /**
   * get page
   */
  get ids() {
    const ids: number[] = [];
    this.entities.map(entity => {
      ids.push(entity.id);
    });
    return ids;
  }

  /**
   * Has more
   */
  get hasMore() {
    return this.entities.length < this.count;
  }

  /**
   *
   * @param entities
   * @param reset
   */
  public update(entities: any, reset: boolean = false) {

    /**
     * Reset list entity
     */
    if (reset) {
      this.entities = [];
    }

    /**
     * return list entities
     */
    if (!entities) { return this; }

    /**
     *
     */
    const { edges, count } = entities;

    this.count = count;
    this.save(edges);
    return this;
  }

  /**
   * Save entities
   * @param entities
   */
  public save(entities: any[]) {
    const Entity = this._entity_interface;
    if (!entities) {
      return this;
    }

    // @ts-ignore
    const $entities: any[] = [];

    entities.map((entity: any) => {
      const $entity = new Entity();
      $entity.save(entity);
      $entities.push($entity);
    });

    this.entities = $entities;
    return this;
  }

  /**
   * add more entities
   * @param entities
   */
  public add(entities: any) {
    const Entity = this._entity_interface;
    if (!entities) {
      return this;
    }
    const { edges, count } = entities;
    this.count = count;

    edges.map((entity: any) => {
      const $entity = new Entity();
      $entity.save(entity);
      this.addEntity($entity);
    });

    return this;
  }



  /**
   * Add entity
   * @param entity
   */
  public addEntity(entity: any) {
    if (!this.checkExist(this.entities, entity)) {
      this.entities.push(entity);
    }
  }


  /**
   * Check Exist
   * @param {any[]} entities
   * @param entity
   * @returns {any}
   */
  private checkExist(entities: any[], entity: any) {
    return [...entities]
      .filter($entity => {
        if(typeof entity.id !== 'undefined'){
          return entity.id === $entity.id;
        }
      })
      .shift();
  }
}

export default Entities;
