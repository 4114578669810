"use strict";
module.exports = {
    "en": {
        "shortlink": "Short Link",
        "experience-text": "Do you want to manage, edit shortened links, and create your own branded domain?",
        "free-trial": "Free Trial",
        "redirecting": "Redirecting to:",
        "path": "To {{value}}",
        "max_input": "Link must be at most {{value}} characters.",
        "min_input": "Link must be at least {{value}} characters.",
        "optimization": "Optimize link on Facebook",
        "breadcrumb": {
            "detail": "General information",
            "edit": "Edit Link",
            "redirect": "Edit Redirect",
            "qrcode": "Edit QR Code",
            "list": "Link list"
        },
        "menu": {
            "back": "Back",
            "home": "Home",
            "menu": "Menu",
            "display": "Edit Display",
            "custom": "Edit Link",
            "preview": "Edit Display",
            "advanced": "Remarketing",
            "campaign": "Campaign",
            "bio_page": "BIO Page",
            "qrcode": "QR Code",
            "protected": "Protected",
            "redirect": "Routing",
            "links": "Links"
        },
        "toolbars": {
            "menu": "Menu",
            "edit": "Edit",
            "redirect": "Redirect",
            "campaign": "Campaign",
            "qrcode": "QR Code",
            "view": "Link Information",
            "frame": "QR Frame",
            "data": "Data",
            "eye": "Pattern",
            "logo": "Logo",
            "color": "Color",
            "protected": "Protected",
            "links": "Links"
        },
        "title": {
            "create": "Create Short Link",
            "qrcode": "QR Code Settings",
            "copy": "Copy Link",
            "edit": "Edit Link",
            "view": "Link List",
            "redirect": "Redirect"
        },
        "qrcode": {
            "title": "QR Code Settings",
            "updated": "QR code design saved"
        },
        "copy.link": "Copy Link",
        "edit.title": "Edit Link",
        "redirect.title": "Redirect",
        "view.title": "Link List",
        "view.detail": "Link view details",
        "device.detail": "Link view details",
        "channel.detail": "Link channel details",
        "list": {
            "title": "Link List",
            "hidden.title": "Hidden Link List",
            "more": "More links",
            "expired": "Link not working due to expiration date set.",
            "inactive": "Link not working because in hidden mode",
            "search.placeholder": "Enter keyword",
            "btn": {
                "unpublished": "Hide Link",
                "published": "Publish Link"
            }
        },
        "stats": {
            "total": {
                "view": "{{value}} views",
                "views": "{{value}} views"
            },
            "shorted": {
                "link": "{{value}} short link",
                "links": "{{value}} short links"
            },
            "guest": {
                "link": "{{value}} guest short link",
                "links": "{{value}} guest short links"
            }
        },
        "statsTwo": {
            "total": {
                "view": "Views",
                "views": "Views"
            },
            "shorted": {
                "link": "Short link",
                "links": "Short links"
            },
            "guest": {
                "link": "Guest short link",
                "links": "Guest short links"
            }
        },
        "views": {
            "label": "views",
            "style1": '<span style="color: #212121;">{{value}}</span> views',
            "style2": '{{value}}<tspan class="highcharts-br" dy="21" x="150">​</tspan> views',
            "total": 'Total: <span style="color:#000000">{{value}}</span> views',
            "latest": "Latest Views",
            "most": "Most Views",
            "least": "Least Views",
            "qrcode": "QR Code Scans",
            "az": "A to Z Order",
            "za": "Z to A Order"
        },
        "shorted": {
            "latest": "Latest Shortened Link",
            "longest": "Longest Shortened Link"
        },
        "notify": {
            "exists": "The link already exists.",
            "unknowError": "Error: Unknown error.",
            "domainAccess.denied": "You do not have permission to select this domain.",
            "exceedTheAllowedQuantity": {
                "limit": "You cannot shorten more links. Exceeded the allowed quantity.",
                "shorten": "You cannot shorten more links. Exceeded the allowed quantity.",
                "alias": "You cannot change the alias. Exceeded the allowed quantity.",
                "link": "You cannot change the original link. Exceeded the allowed quantity."
            },
            "odd.title": "List of {{value}} link",
            "even.title": "List of {{value}} links",
            "unpublished": {
                "odd.title": "List of {{value}} hidden link",
                "even.title": "List of {{value}} hidden links"
            }
        },
        "link": {
            "original.label": "Original link",
            "original.placeholder": "Enter the original link you want to shorten",
            "detail": {
                "real": "Real time",
                "today": "Today",
                "week": "a week",
                "month": "a month",
                "beat": "Viewers by time of day",
                "channel": {
                    "referral": "referral",
                    "channel": "Channel",
                    "backlink": "Backlink"
                },
                "device": {
                    "phone": "Phone",
                    "os": "OS",
                    "browser": "Browser",
                    "device": "Device"
                }
            }
        },
        "links": {
            "title": "List of links",
            "btn": {
                "add": "Create shortened link",
                "edit": "Edit link",
                "view": "View link",
                "delete": "Delete link",
                "cancel": "Cancel"
            },
            "column": {
                "name": "Title",
                "info": "Shortened link",
                "view": "Views",
                "created": "Creation date",
                "auth": "Creator"
            },
            "actions": {
                "published": "Publish",
                "unpublished": "Unpublished",
                "move": "Move campaign",
                "delete": "Delete links",
                "cancel": "Cancel"
            }
        },
        "filter.actions.unpublished": "Hidden link",
        "popup": {
            "display": "Display settings",
            "SEOSettings": "SEO settings",
            "cropping": "Image display ratio",
            "filter.placeholder": "Links filter",
            "publish": {
                "link": "Are you sure you want to publish {{value}} link?",
                "links": "Are you sure you want to publish {{value}} links?"
            },
            "delete": {
                "link": "Are you sure you want to delete {{value}} link?",
                "links": "Are you sure you want to delete {{value}} links?"
            },
            "hide": {
                "link": "Are you sure you want to hide {{value}} link?",
                "links": "Are you sure you want to hide {{value}} links?"
            }
        },
        "link.required": "You have not entered a link",
        "url": {
            "label": "Shortened link",
            "required": "You have not entered a URL",
            "placeholder": "Shortened link",
            "minimum_url_length": "The shortened URL is too short.",
            "invalid": "Invalid URL."
        },
        "form": {
            "title": "List of shortened links",
            "delete": {
                "btn": {
                    "link": "Delete {{value}} link",
                    "links": "Delete {{value}} links"
                }
            },
            "publish": {
                "btn": {
                    "link": "Publish {{value}} link",
                    "links": "Publish {{value}} links"
                }
            },
            "hide": {
                "btn": {
                    "link": "Hide {{value}} link",
                    "links": "Hide {{value}} links"
                }
            },
            "seo.updated": "Information saved.",
            "url": {
                "title": "Customize shortened link",
                "updated": "Shortened link customization saved."
            },
            "edit.title": "Customize shortened link",
            "protected": {
                "title": "Create password and expiration date",
                "help": "To enhance the security of the link you want to share",
                "expiration": "Expiration date",
                "updated": "Password and expiration date for shortened link updated.",
                "password.label": "Password",
                "password.title": "Password-protect a link",
                "date.title": "Set an expiry date and time"
            },
            "display": {
                "label": "Display preview",
                "title": "Customize display",
                "updated": "Shortened link display customization updated.",
                "description": {
                    "label": "Description",
                    "empty": "You have no description",
                    "add": "Add description"
                },
                "edit": {
                    "title": "Title",
                    "description": "Description",
                    "note": {
                        "title": "The SEO title of the link is the first thing people see on Google, social media platforms, or SMS. Use words to let people know what your link page is about.",
                        "description": "Let people know more about your link content in the most concise words."
                    }
                }
            },
            "custom": {
                "title": "Customize shortened link",
                "updated": "Shortened link updated.",
                "utm.label": 'Add UTM <span style="font-size: 12px; color: #686868">(optional)</span>',
                "source": {
                    "label": "Source",
                    "placeholder": "e.g., google, xem.link"
                },
                "medium": {
                    "label": "Medium",
                    "placeholder": "e.g., cpc, banner, email"
                },
                "campaign": {
                    "label": "Campaign",
                    "placeholder": "e.g., spring_sale"
                },
                "term": {
                    "label": "Term (optional)",
                    "placeholder": "e.g., something"
                },
                "content": {
                    "label": "Content (optional)",
                    "placeholder": "e.g., something else"
                }
            },
            "domain": {
                "title": "Select domain",
                "label": "Select domain",
                "placeholder": "Enter the domain you are looking for"
            },
            "qrcode": {
                "title": "QR code",
                "updated": "QR code updated."
            },
            "campaign": {
                "title": "Campaign",
                "help": "This helps you or your team know what this link is used for",
                "updated": "Shortened link campaign updated.",
                "category": {
                    "label": "Campaign",
                    "placeholder": "Enter the campaign name"
                },
                "note": {
                    "label": "Note",
                    "placeholder": "Enter note content"
                }
            },
            "advanced": {
                "title": "Advanced data",
                "default": "Default",
                "help": "To enhance the security of the link you want to share",
                "updated": "Advanced data for shortened link updated.",
                "google.label": "Google Analytics 4",
                "facebook.label": "Facebook Pixel"
            },
            "redirect": {
                "title": "App redirect settings",
                "label": "Customize shortened link",
                "updated": "Shortened link redirect settings updated.",
                "country": "Country",
                "description": "Instead of a random link, you can customize it to your liking",
                "store": {
                    "default": "Search in app store",
                    "custom": "Enter direct link"
                },
                "gplay": {
                    "default": "Search in Google Play",
                    "custom": "Enter direct link"
                }
            },
            "field": {
                "info": "Display customization",
                "origin": "Origin",
                "custom": "Custom",
                "qrcode": "QR code",
                "keyword": "Keyword",
                "password": "Password",
                "title": "Title",
                "description": "Description",
                "appKeyword": "Enter the app to search",
                "ios": {
                    "title": "iOS",
                    "label": "iOS",
                    "placeholder": "Enter the name of the app",
                    "url.placeholder": "Enter the url of the app"
                },
                "android": {
                    "title": "Android",
                    "label": "Android",
                    "placeholder": "Enter the name of the app",
                    "url.placeholder": "Enter the url of the app"
                }
            },
            "btn.save": "Save changes"
        }
    },
    "global": {
        "password": "Mật khẩu"
    },
    "vi": {
        "shortlink": "R\xfat Gọn Link",
        "experience-text": "N\xeáu bạn muốn quản lý, chỉnh sửa các li\xean k\xeát rút gọn v\xe0 tạo t\xean mi\xeàn thương hi\xeạu ri\xeang?",
        "free-trial": "D\xf9ng Thử Miễn Ph\xed",
        "redirecting": "Đang chuyển hướng tới:",
        "path": "Tới {{value}}",
        "max_input": "Link phải c\xf3 nhiều nhất {{value}} k\xfd tự.",
        "min_input": "Link phải c\xf3 \xedt nhất {{value}} k\xfd tự.",
        "optimization": "Tối ưu h\xf3a đường link tr\xean Facebook",
        "breadcrumb": {
            "detail": "Th\xf4ng tin tổng hợp",
            "edit": "Chỉnh sửa link",
            "redirect": "Chỉnh sửa điều hướng",
            "qrcode": "Chỉnh sửa Qr Code",
            "list": "Danh s\xe1ch link"
        },
        "menu": {
            "back": "Back",
            "home": "Trang chủ",
            "menu": "Menu",
            "display": "Sửa hiển thị",
            "custom": "Sửa Link",
            "preview": "Sửa hiển thị",
            "advanced": "Remarketing",
            "campaign": "Chiến dịch",
            "bio_page": "BIO page",
            "qrcode": "QR Code",
            "protected": "Bảo mật",
            "redirect": "Routing",
            "links": "Links"
        },
        "toolbars": {
            "menu": "Menu",
            "edit": "Chỉnh sửa",
            "redirect": "Điều hướng",
            "campaign": "Chiến dịch",
            "qrcode": "QR Code",
            "view": "Th\xf4ng tin link",
            "frame": "Khung QR",
            "data": "Dữ liệu",
            "eye": "Hoa văn",
            "logo": "Logo",
            "color": "M\xe0u sắc",
            "protected": "Bảo mật",
            "links": "Links"
        },
        "title": {
            "create": "Tạo link r\xfat gọn",
            "qrcode": "C\xe0i đặt QR Code",
            "copy": "Copy Link",
            "edit": "Chỉnh sửa link",
            "view": "Danh s\xe1ch link",
            "redirect": "Điều hướng"
        },
        "qrcode": {
            "title": "C\xe0i đặt QR Code",
            "updated": "Đ\xe3 lưu thiết kế QR code"
        },
        "copy.link": "Copy Link",
        "edit.title": "Chỉnh sửa link",
        "redirect.title": "Điều hướng",
        "view.title": "Danh s\xe1ch link",
        "list": {
            "title": "Danh s\xe1ch link",
            "hidden.title": "Danh s\xe1ch link ẩn",
            "btn": {
                "unpublished": "Tạm ẩn link",
                "published": "C\xf4ng bố link"
            }
        },
        "stats": {
            "total": {
                "view": "{{value}} lượt xem",
                "views": "{{value}} lượt xem"
            },
            "shorted": {
                "link": "{{value}} link r\xfat gọn",
                "links": "{{value}} link r\xfat gọn "
            },
            "guest": {
                "link": "{{value}} link kh\xe1ch r\xfat gọn",
                "links": "{{value}} link kh\xe1ch r\xfat gọn"
            }
        },
        "statsTwo": {
            "total": {
                "view": "Lượt xem",
                "views": "Lượt xem"
            },
            "shorted": {
                "link": "Link r\xfat gọn",
                "links": "Link r\xfat gọn "
            },
            "guest": {
                "link": "Link kh\xe1ch r\xfat gọn",
                "links": "Link kh\xe1ch r\xfat gọn"
            }
        },
        "views": {
            "label": "lượt xem",
            "style1": '<span style="color: #212121;" >{{value}}</span> lượt xem',
            "style2": '{{value}}<tspan class="highcharts-br" dy="21" x="150">​</tspan> lượt xem',
            "total": 'Tổng: <span style="color:#000000">{{value}}</span> lượt xem',
            "latest": "Lượt xem mới nhất",
            "most": "Lượt xem nhiều nhất",
            "least": "Lượt xem \xedt nhất",
            "qrcode": "Lượt qu\xe9t m\xe3 Qr Code",
            "az": "Thứ tự từ A tới Z",
            "za": "Thứ tự từ Z tới A"
        },
        "shorted": {
            "latest": "Link mới r\xfat gọn",
            "longest": "Link r\xfat gọn l\xe2u nhất"
        },
        "notify": {
            "exists": "Li\xean kết đ\xe3 tồn tại.",
            "unknowError": "Lỗi: Lỗi kh\xf4ng x\xe1c định.",
            "domainAccess.denied": "Bạn kh\xf4ng c\xf3 quyền chọn domain n\xe0y.",
            "exceedTheAllowedQuantity": {
                "limit": "Bạn kh\xf4ng thể r\xfat gọn th\xeam được. Vượt qu\xe1 số lượng cho ph\xe9p.",
                "shorten": "Bạn kh\xf4ng thể r\xfat gọn th\xeam được. Vượt qu\xe1 số lượng cho ph\xe9p.",
                "alias": "Bạn kh\xf4ng thể thay đổi alias. Vượt qu\xe1 số lượng cho ph\xe9p.",
                "link": "Bạn kh\xf4ng thể thay đổi link gốc. Vượt qu\xe1 số lượng cho ph\xe9p."
            },
            "odd.title": "Danh s\xe1ch {{value}} link",
            "even.title": "Danh s\xe1ch {{value}} links",
            "unpublished": {
                "odd.title": "Danh s\xe1ch {{value}} link ẩn",
                "even.title": "Danh s\xe1ch {{value}} links ẩn"
            }
        },
        "links": {
            "title": "Danh s\xe1ch link",
            "btn": {
                "add": "Tạo link r\xfat gọn",
                "edit": "Sửa link",
                "view": "Xem link",
                "delete": "Xo\xe1 link",
                "cancel": "Huỷ bỏ"
            },
            "column": {
                "name": "Ti\xeau đề",
                "info": "Link r\xfat gọn",
                "view": "Lượt xem",
                "created": "Ng\xe0y tạo",
                "auth": "Người tạo"
            },
            "actions": {
                "published": "C\xf4ng khai",
                "unpublished": "Ẩn link",
                "move": "Di chuyển chiến dịch",
                "delete": "Xo\xe1 links",
                "cancel": "Huỷ bỏ"
            }
        },
        "filter.actions.unpublished": "Link ẩn",
        "popup": {
            "display": "Tuỳ chỉnh hiển thị",
            "SEOSettings": "C\xe0i đặt SEO",
            "cropping": "Tỷ Lệ Ảnh Hiển Thị",
            "filter.placeholder": "Links filter",
            "publish": {
                "link": "Bạn c\xf3 chắc m\xecnh c\xf4ng bố {{value}} link chứ ?",
                "links": "Bạn c\xf3 chắc m\xecnh c\xf4ng bố {{value}} links chứ ?"
            },
            "delete": {
                "link": "Bạn c\xf3 chắc m\xecnh sẽ xo\xe1 {{value}} link chứ ?",
                "links": "Bạn c\xf3 chắc m\xecnh sẽ xo\xe1 {{value}} links chứ ?"
            },
            "hide": {
                "link": "Bạn c\xf3 chắc m\xecnh sẽ ẩn {{value}} link chứ ?",
                "links": "Bạn c\xf3 chắc m\xecnh sẽ ẩn {{value}} links chứ ?"
            }
        },
        "link.required": "Bạn chưa nhập link",
        "url": {
            "label": "Link r\xfat gọn",
            "required": "Bạn chưa nhập url",
            "placeholder": "Link r\xfat gọn",
            "minimum_url_length": "Url r\xfat gọn qu\xe1 ngắn.",
            "invalid": "Url kh\xf4ng hợp lệ."
        },
        "form": {
            "title": "Danh s\xe1ch link r\xfat gọn",
            "delete.btn": {
                "link": "Xo\xe1 {{value}} link",
                "links": "Xo\xe1 {{value}} links"
            },
            "publish.btn": {
                "link": "C\xf4ng bố {{value}} link",
                "links": "C\xf4ng bố {{value}} links"
            },
            "hide": {
                "btn": {
                    "link": "Ẩn {{value}} link",
                    "links": "Ẩn {{value}} links"
                }
            },
            "seo.updated": "Đ\xe3 lưu th\xf4ng tin.",
            "url": {
                "title": "Tuỳ chỉnh link r\xfat gọn",
                "updated": "Đ\xe3 lưu tuỳ chỉnh link r\xfat gọn."
            },
            "edit.title": "Tuỳ chỉnh link r\xfat gọn",
            "protected": {
                "title": "Tạo mật khẩu v\xe0 ng\xe0y hết hạn",
                "help": "Để truy cập n\xe2ng cao t\xednh bảo mật của đường link bạn muốn chia sẻ",
                "password": "Mật khẩu",
                "expiration": "Ng\xe0y hết hạn",
                "updated": "Đ\xe3 cập nhật mật khẩu v\xe0 ng\xe0y hết hạn link r\xfat gọn."
            },
            "display": {
                "label": "Xem trước hiển thị",
                "title": "Tuỳ chỉnh hiển thị",
                "updated": "Đ\xe3 cập nhật tuỳ chỉnh hiển thị link r\xfat gọn.",
                "description": {
                    "label": "M\xf4 tả",
                    "empty": "Bạn chưa c\xf3 m\xf4 tả",
                    "add": "Th\xeam m\xf4 tả"
                },
                "edit": {
                    "title": "Ti\xeau đề",
                    "description": "M\xf4 tả",
                    "note": {
                        "title": "Ti\xeau đề SEO của link l\xe0 điều đầu ti\xean mọi người nh\xecn thấy tr\xean Google, c\xe1c nền tảng mạng x\xe3 hội hoặc sms. Sử dụng c\xe1c từ ngữ để cho mọi người biết trang link của bạn n\xf3i về điều g\xec",
                        "description": "Cho mọi người biết th\xeam về nội dung link của bạn bằng những ng\xf4n từ t\xf3m tắt nhất."
                    }
                }
            },
            "custom": {
                "title": "Tuỳ chỉnh link r\xfat gọn",
                "updated": "Đ\xe3 cập nhật link r\xfat gọn.",
                "utm.label": 'Th\xeam UTM <span style="font-size: 12px; color: #686868">(optional)</span>',
                "source": {
                    "label": "Source",
                    "placeholder": "e.g., google, xem.link"
                },
                "medium": {
                    "label": "Medium",
                    "placeholder": "e.g., cpc, banner, email"
                },
                "campaign": {
                    "label": "Campaign",
                    "placeholder": "e.g., spring_sale"
                },
                "term": {
                    "label": "Term (optional)",
                    "placeholder": "e.g., something"
                },
                "content": {
                    "label": "Content (optional)",
                    "placeholder": "e.g., something else"
                }
            },
            "domain": {
                "title": "Chọn t\xean miền",
                "label": "Chọn t\xean miền",
                "placeholder": "Nhập domain cần t\xecm"
            },
            "qrcode": {
                "title": "Qr code",
                "updated": "Đ\xe3 cập nhật qrcode."
            },
            "campaign": {
                "title": "Chiến dịch",
                "help": "Điều n\xe0y gi\xfap cho bạn hoặc nh\xf3m l\xe0m việc biết link n\xe0y d\xf9ng để l\xe0m g\xec",
                "updated": "Đ\xe3 cập nhật chiến dịch link r\xfat gọn.",
                "category": {
                    "label": "Chiến dịch",
                    "placeholder": "T\xean chiến dịch cần tạo"
                },
                "note": {
                    "label": "Ghi ch\xfa",
                    "placeholder": "Nhập nội dung ghi ch\xfa"
                }
            },
            "advanced": {
                "title": "Dữ liệu n\xe2ng cao",
                "default": "Mặc định",
                "help": "Để truy cập n\xe2ng cao t\xednh bảo mật của đường link bạn muốn chia sẻ",
                "updated": "Đ\xe3 cập nhật dữ liệu n\xe2ng cao link r\xfat gọn.",
                "google.label": "Google Analytics 4",
                "facebook.label": "Facebook Pixel"
            },
            "redirect": {
                "title": "Chuyển hướng c\xe0i đặt app",
                "label": "T\xf9y chỉnh link r\xfat gọn",
                "updated": "Đ\xe3 cập nhật chuyển hướng c\xe0i đặt link r\xfat gọn.",
                "country": "Quốc gia",
                "description": "Thay v\xec đường link ngẫu nhi\xean, bạn c\xf3 thể sửa lại 1 đường link theo \xfd th\xedch",
                "store": {
                    "default": "T\xecm kiếm trong app store",
                    "custom": "Nhập link trực tiếp"
                },
                "gplay": {
                    "default": "T\xecm kiếm trong Google play",
                    "custom": "Nhập link trực tiếp"
                }
            },
            "field": {
                "info": "Tuỳ chỉnh hiển thị",
                "origin": "Origin",
                "custom": "Custom",
                "qrcode": "qrcode",
                "keyword": "keyword",
                "password": "Mật khẩu",
                "title": "Ti\xeau đề",
                "description": "M\xf4 tả",
                "ios": "IOS",
                "android": "Android",
                "appKeyword": "Nhập v\xe0o app cần t\xecm kiếm"
            },
            "btn.save": "Lưu thay đổi"
        }
    }
};
