import settings from "@xem/config";
import Action from "@xem/entity/classes/Action";
const gen = {
    list: "/gens.html",
    listpage: "/gens/:page/page.html",
    create: "/gen/create.html",
    edit: "/gen/:id/edit.html"
};
export const map = (param)=>{
    let { id , page , path  } = param;
    return "".concat(gen[path]).replace(/:id/, id).replace(/:page/, page);
};
const perms = [
    "isAdmin",
    "isAdminGen"
];
const styleConfigs = {
    style: {
        "--button-bar-height": "50px"
    }
};
export const GenRoute = {
    ...styleConfigs,
    ...settings.route,
    action: Action.instanse(),
    runtimejs: true,
    perms,
    exact: true,
    webType: "cpanel",
    mobile: {
        show: true
    },
    classNameOnPage: "cpanel theme-mobile"
};
export default gen;
