"use strict";
module.exports = {
    "en": {
        "title": "Home",
        "home": "Home",
        "dashboard": {
            "title": "Overview",
            "breadcrumb": "Overview",
            "data": {
                "channel.most": "Top visited channels",
                "location.most": "Top viewed location",
                "domain": "Data by domain xem.link",
                "brand": {
                    "domain": "Data by brand domain",
                    "most": "Top viewed links by brand domain"
                },
                "link.most": "Top view link",
                "campaign.most": "Top viewed campaigns"
            }
        },
        "data": "Aggregate data",
        "status": {
            "loading": "Loading...",
            "empty": "No data."
        },
        "location": {
            "column": {
                "name": "Name",
                "view": "Views"
            }
        }
    },
    "vi": {
        "title": "Trang chủ",
        "home": "Trang chủ",
        "dashboard": {
            "title": "Tổng quan",
            "breadcrumb": "Tổng quan"
        },
        "data": "Tổng hợp dữ liệu",
        "status": {
            "loading": "Đang tải...",
            "empty": "Kh\xf4ng c\xf3 dữ liệu."
        },
        "location": {
            "column": {
                "name": "T\xean",
                "view": "Lượt xem"
            }
        }
    }
};
