export function getFilterByKey(key) {
    let orderBy = "CREATED";
    let direction = "DESC";
    let status = "active";
    return {
        status,
        direction,
        orderBy
    };
}
