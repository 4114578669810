import USERS_QUERY from "@xem/user-common/graphql/UsersSearch.graphql";
import { withContentCallback } from "@xem/entity/classes/preloadContents";
export default function userSearch(name) {
    return withContentCallback((res)=>{
        var ref;
        let data = [];
        if (res === null || res === void 0 ? void 0 : (ref = res.edges) === null || ref === void 0 ? void 0 : ref.length) {
            res === null || res === void 0 ? void 0 : res.edges.map((value)=>data.push(value));
        }
        return data;
    }, name, USERS_QUERY);
}
