import ClientModule from "@gqlapp/module-client-react";
import compose from "recompose/compose";
import Search from "./search";
import Country from "./country";
import Confirm from "./confirm";
import Date from "./date";
import Time from "./time";
import Select from "./select";
import Menu from "./menu";
import Click from "./click";
import Toolbar from "./click/toolbar";
import Crop from "./crop";
import User from "./user";
import Gradient from "./colors/gradient";
import Color from "./colors/color";
const popupComponents = [
    {
        component: compose(Confirm, Search, Country, Date, Time, Select, Menu)
    },
    {
        component: compose(Crop, Click, Toolbar, Gradient, Color)
    },
    {
        component: User,
        positions: [
            "web",
            "textsocial"
        ]
    }
];
export default new ClientModule({
    popupComponents
});
