import withProps from "recompose/withProps";
import withRouteLink from "./withRouteLink";
import withTaxonomies from "@xem/taxonomy-common/classes/withTaxonomies";
import Taxonomies from "@xem/taxonomy-common/classes/Taxonomies";
import Charts from "@xem/stats-common/classes/Stats/Charts";
import Stats from "@xem/stats-common/classes/Stats/Stats";
import withWebShare from "@xem/entity-client-react/components/withWebShare";
import withStatsLocation from "@xem/stats-common/classes/withStatsLocation";
import withStatsDevice from "@xem/stats-common/classes/withStatsDevice";
import withStatsChannel from "@xem/stats-common/classes/withStatsChannel";
import withStatsChart from "@xem/stats-common/classes/withStatsChart";
import loadable from "next/dynamic";
import withRenderProcess from "@xem/entity/classes/withRenderProcess";
import withFormFilter from "../helpers/link/Menu";
import withMenu from "../helpers/link/withMenu";
import withCompose from "../helpers/link/Menu.Utils";
const Detail = loadable(()=>import("../components/views/DetailView").then((c)=>c.default), {
    loadableGenerated: {
        webpack: ()=>[
                require.resolveWeak("../components/views/DetailView")
            ]
    }
});
let timeout = null;
const DetailContainer = withRouteLink((param)=>{
    let { query: { id  } = {}  } = param;
    return {
        stats: Stats.instanse(),
        charts: Charts.instanse(),
        taxonomies: Taxonomies.instanse(),
        statsProps: {
            id: parseInt(id),
            skip: !parseInt(id)
        }
    };
}, [
    withCompose,
    withMenu,
    withRenderProcess(),
    withFormFilter,
    withTaxonomies({}),
    withWebShare,
    withStatsLocation((param)=>{
        let { statsProps  } = param;
        return {
            ...statsProps,
            skip: !!__SERVER__
        };
    }),
    withStatsDevice((param)=>{
        let { statsProps , DeviceType: type  } = param;
        return {
            ...statsProps,
            type
        };
    }),
    withStatsChannel((param)=>{
        let { statsProps  } = param;
        return {
            ...statsProps
        };
    }),
    withStatsChart((param)=>{
        let { statsProps , charts  } = param;
        return {
            ...charts.variables,
            ...statsProps
        };
    }),
    withProps((param)=>{
        let { entity , navigation , stats , actions  } = param;
        let { ips  } = stats || {}, { title  } = entity || {};
        /** Open QR */ function toggleQR(e) {
            actions["qrcode"] && actions["qrcode"](e);
        }
        return {
            title,
            ips,
            toggleQR
        };
    })
])(Detail);
export default DetailContainer;
