"use strict";
module.exports = {
    "en": {
        "title": "Campaign",
        "exists": "Campaign already exists",
        "filter": {
            "label": "Sort display",
            "latest": "Latest campaign",
            "assigned": "Assigned campaign",
            "most": "Most viewed campaign",
            "least": "Least viewed campaign",
            "az": "Order from A to Z",
            "za": "Order from Z to A"
        },
        "max_input": "Campaign must have at most {{value}} characters.",
        "min_input": "Campaign must have at least {{value}} characters.",
        "popup.filter.placeholder": "Category search",
        "list": {
            "loadMore": "Load more campaigns",
            "type": {
                "odd.title": "List of {{value}} campaign",
                "even.title": "List of {{value}} campaigns"
            },
            "btn": {
                "delete": "Delete campaign",
                "edit": "Edit campaign",
                "merge": "Merge campaign",
                "assign": "Add manager"
            }
        },
        "label": {
            "merge": "Merge campaign",
            "edit": "Edit campaign",
            "create": "Create new campaign"
        },
        "form": {
            "label": {
                "created": "New campaign created successfully",
                "updated": "Campaign updated successfully",
                "deleted": "Campaign deleted successfully",
                "merge": "Search campaign",
                "name": "Campaign name",
                "description": "Campaign description"
            },
            "placeholder": {
                "name": "Enter campaign name",
                "description": "Enter campaign description"
            },
            "confirm.delete.label": "Delete campaign",
            "actions.cancel": "Cancel",
            "delete.btn.taxonomy": "Delete campaign"
        },
        "assign": {
            "permission": {
                "read": "View",
                "manage": "View & Edit",
                "label": "Access rights",
                "placeholder": "Enter access rights"
            },
            "mail": {
                "label": "Manager's email",
                "placeholder": "Enter email"
            }
        }
    },
    "vi": {
        "title": "Chiến dịch",
        "exists": "Chiến dịch đ\xe3 tồn tại",
        "filter": {
            "label": "Sắp xếp hiển thị",
            "latest": "Chiến dịch mới nhất",
            "assigned": "Chiến dịch được chỉ định",
            "most": "Chiến dịch xem nhiều nhất",
            "least": "Chến dịch xem \xedt nhất",
            "az": "Thứ tự từ A tới Z",
            "za": "Thứ tự từ Z tới A"
        },
        "max_input": "Chiến dịch phải c\xf3 nhiều nhất {{value}} k\xfd tự.",
        "min_input": "Chiến dịch phải c\xf3 \xedt nhất {{value}} k\xfd tự.",
        "popup.filter.placeholder": "Category search",
        "list": {
            "loadMore": "Load more category",
            "btn": {
                "delete": "Xo\xe1 chiến dịch",
                "edit": "Sửa chiến dịch",
                "merge": "Gộp chiến dịch",
                "assign": "Th\xeam người quản l\xfd"
            }
        },
        "label": {
            "merge": "Gộp chiến dịch",
            "edit": "Sửa chiến dịch",
            "create": "Tạo chiến dịch mới"
        },
        "form": {
            "confirm.delete.label": "Xo\xe1 chiến dịch",
            "actions.cancel": "Huỷ",
            "delete.btn.taxonomy": "Xo\xe1 chiến dịch",
            "placeholder": {
                "name": "Nhập t\xean chiến dịch",
                "description": "Nhập m\xf4 tả chiến dịch"
            },
            "label": {
                "created": "Tạo chiến dịch mới th\xe0nh c\xf4ng",
                "updated": "Sửa chiến dịch th\xe0nh c\xf4ng",
                "deleted": "Xo\xe1 chiến dịch th\xe0nh c\xf4ng",
                "merge": "T\xecm kiếm chiến dịch",
                "name": "T\xean chiến dịch",
                "description": "M\xf4 tả chiến dịch"
            }
        },
        "assign": {
            "permission": {
                "read": "Xem",
                "manage": "Xem & Sửa  ",
                "label": "Quyền truy cập",
                "placeholder": "Nhập quyền truy cập"
            },
            "mail": {
                "label": "Email người quản l\xfd",
                "placeholder": "Nhập email"
            }
        }
    }
};
