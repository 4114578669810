import { withFormik } from "formik";
import compose from "recompose/compose";
import withOnFilter from "./withOnFilter";
export default compose(withFormik({
    mapPropsToValues: (param)=>{
        let { entity  } = param;
        return {
            onSelect: 0,
            name: entity === null || entity === void 0 ? void 0 : entity.name,
            note: entity === null || entity === void 0 ? void 0 : entity.note,
            nodes: (entity === null || entity === void 0 ? void 0 : entity.nodes) || []
        };
    },
    handleSubmit: function() {},
    enableReinitialize: true,
    displayName: "FilterForm"
}), withOnFilter);
