import compose from "recompose/compose";
import withRenderTools from "@xem/look/Renders/Tools/withTools";
import withOnFilter from "../helpers/withOnFilter";
import withTaxonomyEdit from "../helpers/withTaxonomyEdit";
import withTaxonomyCreate from "../helpers/withTaxonomyCreate";
import withTaxonomyMerge from "../helpers/withTaxonomyMerge";
import withTaxonomyAssign from "../helpers/withOnAssign";
import withRenderProcess from "@xem/entity/classes/withRenderProcess";
export default compose(withOnFilter, withRenderTools, withTaxonomyCreate, withTaxonomyEdit, withTaxonomyMerge, withTaxonomyAssign, withRenderProcess({
    entityType: "link"
}));
