import ClientModule from "@gqlapp/module-client-react";
import compose from "recompose/compose";
import category from "./category";
const popupComponents = [
    {
        component: compose(category)
    }
];
export default new ClientModule({
    popupComponents
});
