"use strict";
module.exports = {
    "en": {
        "navLink": "Gen nav link",
        "title": "Gen module",
        "meta": "Gen example",
        "welcomeText": "Hello, This is the Gen module",
        "form": {
            "gen": {
                "create": "Create gen",
                "update": "Update gen",
                "name": {
                    "label": "Name",
                    "placeholder": "Name"
                },
                "note": {
                    "label": "Note",
                    "placeholder": "Note"
                }
            },
            "people": {
                "add": "Add People",
                "update": "Update People",
                "name": {
                    "label": "Name",
                    "placeholder": "Name"
                },
                "address": {
                    "label": "Address",
                    "placeholder": "Address"
                },
                "phone": {
                    "label": "Phone",
                    "placeholder": "000 - 000 - 000 - 000"
                },
                "birthday": {
                    "label": "Birthday",
                    "placeholder": "00-00-0000"
                },
                "deathday": {
                    "label": "Death day",
                    "placeholder": "00-00-0000"
                },
                "note": {
                    "label": "Note",
                    "placeholder": "Note"
                },
                "sex": {
                    "label": "Sex",
                    "other": "Other",
                    "female": "Female",
                    "male": "Male"
                }
            }
        }
    },
    "vi": {
        "navLink": "Gen nav link",
        "title": "Gen module",
        "meta": "Gen example",
        "welcomeText": "Hello, This is the Gen module"
    }
};
