/* tslint:disable */ import { QrValueMap } from "@xem/qr-client-react/utils/QrValueMap";
class Access {
    /**
   *
   * @param values
   */ // @ts-ignore
    set access(values) {
        const { accessUpdate , accessAssign , accessData , accessStore , accessMetatags , accessSercurity , accessDelete , accessCustom , accessDisplay , accessCampaign , accessUrl , accessQrDefault  } = values || {};
        this.accessUrl = !!accessUrl;
        this.accessUpdate = !!accessUpdate;
        this.accessDisplay = !!accessDisplay;
        this.accessCustom = !!accessCustom;
        this.accessAssign = !!accessAssign;
        this.accessData = !!accessData;
        this.accessStore = !!accessStore;
        this.accessMetatags = !!accessMetatags;
        this.accessSercurity = !!accessSercurity;
        this.accessDelete = !!accessDelete;
        this.accessCampaign = !!accessCampaign;
        this.accessQrDefault = !!accessQrDefault;
    }
    /**
   * get access
   * @protected
   */ get access() {
        return {
            accessUrl: this.accessUrl,
            accessUpdate: this.accessUpdate,
            accessCustom: this.accessCustom,
            accessAssign: this.accessAssign,
            accessData: this.accessData,
            accessStore: this.accessStore,
            accessMetatags: this.accessMetatags,
            accessSercurity: this.accessSercurity,
            accessDelete: this.accessDelete,
            accessCampaign: this.accessCampaign,
            accessQrDefault: this.accessQrDefault
        };
    }
    constructor(){
        this.accessUrl = false;
        this.accessUpdate = false;
        this.accessCustom = false;
        this.accessDisplay = false;
        this.accessAssign = false;
        this.accessData = false;
        this.accessStore = false;
        this.accessMetatags = false;
        this.accessSercurity = false;
        this.accessDelete = false;
        this.accessCampaign = false;
        this.accessQrDefault = false;
    }
}
export class Base {
    static instanse(data) {
        data = data || {};
        return new this().save(data || {});
    }
    set qr(values) {
        this._qr = QrValueMap(values);
    }
    get qr() {
        return this._qr;
    }
    /**
   * get access
   * @protected
   */ get utm() {
        return this._utm;
    }
    // @ts-ignore
    set info(values) {
        const { id , uid , platform , note , category , assigned , url , full , site , origin , universal  } = values || {};
        const { title , views , domain , author , display , metatags , created , changed  } = values || {};
        const { location , analytics , redirect , visitor , protected: _protected , advanced  } = values || {};
        const { deeplink , type , qrurl , utm , customstore  } = values || {};
        const { images , image_type , image_url  } = values || {};
        const $self = this;
        Object.assign($self, {
            advanced: advanced || {},
            protected: _protected || {},
            id: id || 0,
            uid: uid || 0,
            origin: origin || "",
            universal: universal || "",
            visitor: visitor || "",
            analytics: analytics || {},
            display: display || "origin"
        });
        this._title = title;
        this._type = type;
        this._domain = domain;
        this._images = images;
        this.image_type = image_type;
        this.image_url = image_url;
        this._utm = utm || {};
        this.qrurl = qrurl;
        this.author = author;
        this.views = views || 0;
        this.note = note || "";
        this._url = url;
        this.created = created;
        this.changed = changed;
        this.redirect = !!redirect;
        this.platform = platform;
        this.customstore = customstore;
        this.deeplink = deeplink;
        this.assigned = assigned;
        this.category = category;
        this.location = location;
        this.metatags = metatags;
        this.site = site;
        this.full = full;
    // this.ios      = ios;
    // this.android  = android;
    }
    /**
   * Save data
   * @param values
   */ save(values) {
        this.qr = values === null || values === void 0 ? void 0 : values.qr;
        this.access = values === null || values === void 0 ? void 0 : values.access;
        this.info = values;
        // @ts-ignore
        return this;
    }
    /**
   * {@inheritdoc}
   */ get type() {
        return this._type || "link";
    }
    /**
   * {@inheritdoc}
   */ get domain() {
        return this._domain || false;
    }
    /**
   * {@inheritdoc}
   */ set domain(values) {
        this._domain = values;
    }
    /**
   * {@inheritdoc}
   */ set ios(value) {
        this._IOS = value;
    }
    get ios() {
        return this._IOS || null;
    }
    get store_url() {
        return this._IOS && this._IOS.value ? this._IOS.value : null;
    }
    get gplay_url() {
        return this._ANDROID && this._ANDROID.value ? this._ANDROID.value : null;
    }
    /**
   * @param value
   */ set android(value) {
        this._ANDROID = value;
    }
    get android() {
        return this._ANDROID || null;
    }
    /**
   * {@inheritdoc}
   */ set deeplink(value) {
        this._DEEPLINK = value;
    }
    get deeplink() {
        return this._DEEPLINK || {};
    }
    /**
   *
   * @param value
   */ set protected(value) {
        this._protected = value;
    }
    get protected() {
        return this._protected || {};
    }
    /**
   *
   * @param value
   */ set location(value) {
        this._location = value;
    }
    get location() {
        return this._location || {};
    }
    /**
   * {@inheritdoc}
   */ get ips() {
        const _IPS = [];
        const { page , users , new: new_viewss  } = this.location;
        const addIP = (ip)=>{
            ip = Number(ip);
            if (!_IPS.includes(ip)) {
                _IPS.push(ip);
            }
        };
        if (page && page.length) {
            page.map((param)=>{
                let { ip  } = param;
                return addIP(ip);
            });
        }
        if (users && users.length) {
            users.map((param)=>{
                let { ip  } = param;
                return addIP(ip);
            });
        }
        if (new_viewss && new_viewss.length) {
            new_viewss.map((param)=>{
                let { ip  } = param;
                return addIP(ip);
            });
        }
        return _IPS;
    }
    /**
   * @param value
   */ set analytics(value) {
        this._analytics = value;
    }
    get analytics() {
        return this._analytics || {};
    }
    /**
   *
   * @param value
   */ set category(value) {
        this._category = value || [];
    }
    get category() {
        // @ts-ignore
        return this._category;
    }
    /**
   *
   * @param value
   */ set assigned(value) {
        this._assigned = value;
    }
    get assigned() {
        return this._assigned || [];
    }
    /**
   * get site
   */ get site() {
        return this._site || "";
    }
    set site(value) {
        this._site = value;
    }
    /**
   * get site
   */ get url() {
        return this._url || "";
    }
    set url(value) {
        this._url = value;
    }
    /**
   * Get full path
   */ get full() {
        return this._full || "";
    }
    set full(value) {
        this._full = value;
    }
    /**
   * get title
   */ get og_title() {
        return this.value("title");
    }
    get og_description() {
        return this.value("description");
    }
    get og_image() {
        return this.value("og:image");
    }
    /**
   * set metatags
   * @param value
   */ set metatags(value) {
        this._metatags = value;
    }
    get metatags() {
        return this._metatags || [];
    }
    /**
   * get metatags
   */ get images() {
        const image = this.value("og:image");
        let images = this._images;
        if (!images) {
            images = [
                {
                    url: null,
                    thumb: {
                        url: image
                    },
                    big: {
                        url: image
                    }
                }
            ];
        }
        return (images || []).map((param)=>{
            let { url , big , thumb , ...values } = param;
            big = big || {
                url: url || null
            };
            thumb = thumb || {
                url: url || null
            };
            return {
                ...values,
                url,
                big,
                thumb
            };
        });
    }
    /**
   * get metatags
   */ get image() {
        return [
            ...this.images
        ].shift() || {
            thumb: {
                url: null
            },
            big: {
                url: null
            }
        };
    }
    /**
   * get metatags
   */ get title() {
        return this.value("title") || this._title;
    }
    /**
   * get metatags
   */ get description() {
        return this.value("description");
    }
    /**
   * get metatags
   */ value($key) {
        let _value = this.metatags.filter((param)=>{
            let { key  } = param;
            return key == $key;
        }).shift();
        try {
            _value = _value.value;
        } catch (e) {}
        return _value;
    }
    constructor(){
        this.fetchMore = null;
        this.subscribeToMore = null;
        this.qrurl = "";
        this.redirect = false;
        this.author = "";
        this.note = "";
        this.views = 0;
        this._DEEPLINK = {};
        this.customstore = {};
        this.created = null;
        this.changed = null;
        this.platform = "web";
        this.image_type = null;
        this.image_url = null;
        this.refetch = null;
    }
}
export default Base;
