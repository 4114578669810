import compose from "recompose/compose";
import withProps from "recompose/withProps";
import withLoading from "@xem/utils/withLoading";
import Entities from "@xem/gen-common/classes/Gens";
import withGens, { GENS_QUERY } from "@xem/gen-common/classes/withGens";
import withGensDeleting from "@xem/gen-common/classes/withGensDeleting";
import onDeletes from "../utils/onDeletes";
import { getFilterByKey } from "./Utils";
import setCache from "@xem/entity/classes/setCache";
/**
 *
 * @param input
 * @param withPropsInput
 * @param withPropsBefore
 */ export default ((input, withPropsInput, withPropsBefore)=>{
    return (Route)=>{
        function getEntities(param) {
            let { query , cookie , status ="active"  } = param;
            let id = parseInt(query === null || query === void 0 ? void 0 : query.id);
            let entities = Entities.instanse({
                pageSize: 16,
                orderBy: "CHANGED",
                protected: true
            });
            let orderBy, direction, filter = {};
            cookie && ({ orderBy , direction , status  } = getFilterByKey(cookie));
            !!id && Object.assign(filter, {
                tids: [
                    id
                ]
            });
            !!status && Object.assign(filter, {
                status
            });
            !!orderBy && (entities.orderBy = orderBy);
            !!direction && (entities.direction = direction);
            return entities;
        }
        /**
		 * WithRouteLink
		 */ const WithRouteLink = compose(withProps(input), withGensDeleting, withProps((param)=>{
            let { gensDelete , query , cookie , ...props } = param;
            var ref;
            return {
                onDeletes: onDeletes(gensDelete, props),
                entities: getEntities({
                    query,
                    cookie: cookie === null || cookie === void 0 ? void 0 : (ref = cookie.filter) === null || ref === void 0 ? void 0 : ref.key
                })
            };
        }), ...withPropsBefore || [], withGens((param)=>{
            let { entities  } = param;
            return {
                gens: entities
            };
        }), ...withPropsInput || [], withLoading)(Route);
        const bindCache = async (apolloClient, query, obj)=>{
            let entities = getEntities(obj);
            let variables = entities.variables;
            /** set cache*/ await setCache({
                query,
                variables,
                apolloClient
            });
        };
        // @ts-ignore
        WithRouteLink.getInitialProps = async (param)=>{
            let { apolloClient  } = param;
            let ctx = {};
            await bindCache(apolloClient, GENS_QUERY, {
                ...ctx
            });
            return {
                ...ctx
            };
        };
        return WithRouteLink;
    };
});
