/**
 *
 * @param preview
 */ export default function getPreview(preview) {
    let [width, height] = "".concat(preview).split(":").map((v)=>parseInt(v, 10));
    width = Math.round(width);
    height = Math.round(height);
    return [
        width,
        height
    ];
}
