class TryCatch {

	e = null;
	constructor(callback) {
		try {
			callback()
		}catch (e) {
			this.e = e;
		}
	}

	catch(func){
		if(typeof func === 'function'){
			func(this.e)
		}else{
			console.error(this.e)
		}
	}
}

export default TryCatch;