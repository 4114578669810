import { withFormik } from "formik";
import withProps from "recompose/withProps";
import compose from "recompose/compose";
// @ts-ignore
import Style from "./Filter.Styles";
export default compose(Style, withFormik({
    mapPropsToValues: (param)=>{
        let { values  } = param;
        return {
            ...values,
            onSelect: 0
        };
    },
    handleSubmit: function() {},
    enableReinitialize: true,
    displayName: "FilterForm"
}), withProps((param)=>{
    let { onActions , setFieldValue  } = param;
    const peopleCallback = function peopleCallback(values, param) {
        let { onClose  } = param;
        onClose && onClose();
        debug({
            values
        });
    };
    const callback = (param, param1)=>{
        let { name , note  } = param, { onClose  } = param1;
        onClose && onClose();
        setFieldValue("name", name);
        setFieldValue("note", note);
        onActions["gen.people.add"] && onActions["gen.people.add"]({
            callback: peopleCallback
        });
    };
    const onGenCreate = function onGenCreate(e) {
        e.preventDefault();
        onActions["gen.create"] && onActions["gen.create"]({
            callback
        });
    };
    return {
        onGenCreate
    };
}));
